import React from "react";

export const CorrectedPrivacy = () => {
  return (
    <>
      <div>
        <h1>PRIVACY POLICY</h1>
        <p>
          <strong>Last updated</strong> February 17, 2025
        </p>

        <p>
          This privacy notice for <strong>David Klausz</strong> ("we," "us," or
          "our"), describes how and why we might collect, store, use, and/or
          share ("process") your information when you use our services
          ("Services"), such as when you:
        </p>
        <ul>
          <li>
            Download and use our mobile application (<strong>MoodSense</strong>
            ), or any other application of ours that links to this privacy
            notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
        <p>
          <strong>Questions or concerns?</strong> Reading this privacy notice
          will help you understand your privacy rights and choices. If you do
          not agree with our policies and practices, please do not use our
          Services. If you still have any questions or concerns, please contact
          us at <strong>hellomoodsense@gmail.com</strong>.
        </p>

        <h4>SUMMARY OF KEY POINTS</h4>
        <p>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>
        <ul>
          <li>
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with us and the Services,
            the choices you make, and the products and features you use.{" "}
            <a href="#1">
              Learn more about personal information you disclose to us
            </a>
            .
          </li>
          <li>
            <strong>Do we process any sensitive personal information?</strong>{" "}
            We do not process sensitive personal information.
          </li>
          <li>
            <strong>Do we receive any information from third parties?</strong>{" "}
            We do not receive any information from third parties.
          </li>
          <li>
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so.{" "}
            <a href="#2">Learn more about how we process your information</a>.
          </li>
          <li>
            <strong>
              In what situations and with which parties do we share personal
              information?
            </strong>{" "}
            We may share information in specific situations and with specific
            third parties.{" "}
            <a href="#4">
              Learn more about when and with whom we share your personal
              information
            </a>
            .
          </li>
          <li>
            <strong>How do we keep your information safe?</strong> We have
            organizational and technical processes and procedures in place to
            protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information.{" "}
            <a href="#7">Learn more about how we keep your information safe</a>.
          </li>
          <li>
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information.{" "}
            <a href="#8">Learn more about your privacy rights</a>.
          </li>
          <li>
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by submitting a data subject access request,
            or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </li>
        </ul>
        <p>
          <strong>
            Want to learn more about what we do with any information we collect?
          </strong>{" "}
          Review the privacy notice in full.
        </p>

        <h4>TABLE OF CONTENTS</h4>
        <ol>
          <li>
            <a href="#1">
              <strong>WHAT INFORMATION DO WE COLLECT?</strong>
            </a>
          </li>
          <li>
            <a href="#2">
              <strong>HOW DO WE PROCESS YOUR INFORMATION?</strong>
            </a>
          </li>
          <li>
            <a href="#3">
              <strong>
                WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </strong>
            </a>
          </li>
          <li>
            <a href="#4">
              <strong>
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </strong>
            </a>
          </li>
          <li>
            <a href="#5">
              <strong>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
            </a>
          </li>
          <li>
            <a href="#6">
              <strong>HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
            </a>
          </li>
          <li>
            <a href="#7">
              <strong>HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
            </a>
          </li>
          <li>
            <a href="#8">
              <strong>WHAT ARE YOUR PRIVACY RIGHTS?</strong>
            </a>
          </li>
          <li>
            <a href="#9">
              <strong>CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
            </a>
          </li>
          <li>
            <a href="#10">
              <strong>DO WE MAKE UPDATES TO THIS NOTICE?</strong>
            </a>
          </li>
          <li>
            <a href="#11">
              <strong>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
            </a>
          </li>
          <li>
            <a href="#12">
              <strong>
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </strong>
            </a>
          </li>
        </ol>

        <h4 id="1">
          1. <strong>WHAT INFORMATION DO WE COLLECT?</strong>
        </h4>
        <h3>Personal information you disclose to us</h3>
        <p>
          <strong>In Short:</strong> We collect personal information that you
          provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <strong>Personal Information Provided by You.</strong> The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <ul>
          <li>names</li>
          <li>email addresses</li>
        </ul>
        <p>
          <strong>Sensitive Information.</strong> We do not process sensitive
          information.
        </p>
        <p>
          <strong>Social Media Login Data.</strong> We may provide you with the
          option to register with us using your existing social media account
          details, like your Facebook, Twitter, or other social media account.
          If you choose to register in this way, we will collect the information
          described in the section called{" "}
          <a href="#5">"HOW DO WE HANDLE YOUR SOCIAL LOGINS?"</a> below.
        </p>
        <p>
          <strong>Application Data.</strong> If you use our application(s), we
          also may collect the following information if you choose to provide us
          with access or permission:
        </p>
        <ul>
          <li>
            <strong>Mobile Device Data.</strong> We automatically collect device
            information (such as your mobile device ID, model, and
            manufacturer), operating system, version information and system
            configuration information, device and application identification
            numbers, browser type and version, hardware model Internet service
            provider and/or mobile carrier, and Internet Protocol (IP) address
            (or proxy server). If you are using our application(s), we may also
            collect information about the phone network associated with your
            mobile device, your mobile device’s operating system or platform,
            the type of mobile device you use, your mobile device’s unique
            device ID, and information about the features of our application(s)
            you accessed.
          </li>
          <li>
            <strong>Push Notifications.</strong> We may request to send you push
            notifications regarding your account or certain features of the
            application(s). If you wish to opt out from receiving these types of
            communications, you may turn them off in your device's settings.
          </li>
        </ul>
        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>

        <h3>Information automatically collected</h3>
        <p>
          <strong>In Short:</strong> Some information — such as your Internet
          Protocol (IP) address and/or browser and device characteristics — is
          collected automatically when you visit our Services.
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>The information we collect includes:</p>
        <ul>
          <li>
            <strong>Log and Usage Data.</strong> Log and usage data is
            service-related, diagnostic, usage, and performance information our
            servers automatically collect when you access or use our Services
            and which we record in log files. Depending on how you interact with
            us, this log data may include your IP address, device information,
            browser type, and settings and information about your activity in
            the Services (such as the date/time stamps associated with your
            usage, pages and files viewed, searches, and other actions you take
            such as which features you use), device event information (such as
            system activity, error reports (sometimes called "crash dumps"), and
            hardware settings).
          </li>
          <li>
            <strong>Device Data.</strong> We collect device data such as
            information about your computer, phone, tablet, or other device you
            use to access the Services. Depending on the device used, this
            device data may include information such as your IP address (or
            proxy server), device and application identification numbers,
            location, browser type, hardware model, Internet service provider
            and/or mobile carrier, operating system, and system configuration
            information.
          </li>
          <li>
            <strong>Location Data.</strong> We collect location data such as
            information about your device's location, which can be either
            precise or imprecise. How much information we collect depends on the
            type and settings of the device you use to access the Services. For
            example, we may use GPS and other technologies to collect
            geolocation data that tells us your current location (based on your
            IP address). You can opt out of allowing us to collect this
            information either by refusing access to the information or by
            disabling your Location setting on your device. However, if you
            choose to opt out, you may not be able to use certain aspects of the
            Services.
          </li>
        </ul>

        <h4 id="2">
          2. <strong>HOW DO WE PROCESS YOUR INFORMATION?</strong>
        </h4>
        <p>
          <strong>In Short:</strong> We process your information to provide,
          improve, and administer our Services, communicate with you, for
          security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent.
        </p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <ul>
          <li>
            To facilitate account creation and authentication and otherwise
            manage user accounts. We may process your information so you can
            create and log in to your account, as well as keep your account in
            working order.
          </li>
          <li>
            To identify usage trends. We may process information about how you
            use our Services to better understand how they are being used so we
            can improve them.
          </li>
          <li>
            To save or protect an individual's vital interest. We may process
            your information when necessary to save or protect an individual’s
            vital interest, such as to prevent harm.
          </li>
        </ul>

        <h4 id="3">
          3.{" "}
          <strong>
            WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </strong>
        </h4>
        <p>
          <strong>In Short:</strong> We only process your personal information
          when we believe it is necessary and we have a valid legal reason
          (i.e., legal basis) to do so under applicable law, like with your
          consent, to comply with laws, to provide you with services to enter
          into or fulfill our contractual obligations, to protect your rights,
          or to fulfill our legitimate business interests.
        </p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <ul>
          <li>
            <strong>Consent.</strong> We may process your information if you
            have given us permission (i.e., consent) to use your personal
            information for a specific purpose. You can withdraw your consent at
            any time. Learn more about withdrawing your consent.
          </li>
          <li>
            <strong>Legitimate Interests.</strong> We may process your
            information when we believe it is reasonably necessary to achieve
            our legitimate business interests and those interests do not
            outweigh your interests and fundamental rights and freedoms. For
            example, we may process your personal information for some of the
            purposes described in order to:
            <ul>
              <li>
                Analyze how our Services are used so we can improve them to
                engage and retain users
              </li>
            </ul>
          </li>
          <li>
            <strong>Legal Obligations.</strong> We may process your information
            where we believe it is necessary for compliance with our legal
            obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
          </li>
          <li>
            <strong>Vital Interests.</strong> We may process your information
            where we believe it is necessary to protect your vital interests or
            the vital interests of a third party, such as situations involving
            potential threats to the safety of any person.
          </li>
        </ul>

        <h4 id="4">
          4.{" "}
          <strong>
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </strong>
        </h4>
        <p>
          <strong>In Short:</strong> We may share information in specific
          situations described in this section and/or with the following third
          parties.
        </p>
        <p>
          <strong>
            Vendors, Consultants, and Other Third-Party Service Providers.
          </strong>{" "}
          We may share your data with third-party vendors, service providers,
          contractors, or agents ("third parties") who perform services for us
          or on our behalf and require access to such information to do that
          work. We have contracts in place with our third parties, which are
          designed to help safeguard your personal information. This means that
          they cannot do anything with your personal information unless we have
          instructed them to do it. They will also not share your personal
          information with any organization apart from us. They also commit to
          protect the data they hold on our behalf and to retain it for the
          period we instruct. The third parties we may share personal
          information with are as follows:
        </p>
        <ul>
          <li>
            <strong>
              Allow Users to Connect to Their Third-Party Accounts
            </strong>
            <ul>
              <li>Google account and Apple SignIn</li>
            </ul>
          </li>
          <li>
            <strong>Cloud Computing Services</strong>
            <ul>
              <li>Google Cloud Platform</li>
            </ul>
          </li>
          <li>
            <strong>User Account Registration and Authentication</strong>
            <ul>
              <li>Google OAuth 2.0 and Apple SignIn</li>
            </ul>
          </li>
          <li>
            <strong>Web and Mobile Analytics</strong>
            <ul>
              <li>Google Analytics and MixPanel</li>
            </ul>
          </li>
          <li>
            <strong>Website Performance Monitoring</strong>
            <ul>
              <li>Sentry</li>
            </ul>
          </li>
        </ul>
        <p>
          We also may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
        </ul>

        <h4 id="5">
          5. <strong>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
        </h4>
        <p>
          <strong>In Short:</strong> If you choose to register or log in to our
          Services using a social media account, we may have access to certain
          information about you.
        </p>
        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>

        <h4 id="6">
          6. <strong>HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
        </h4>
        <p>
          <strong>In Short:</strong> We keep your information for as long as
          necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>

        <h4 id="7">
          7. <strong>HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
        </h4>
        <p>
          <strong>In Short:</strong> We aim to protect your personal information
          through a system of organizational and technical security measures.
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>

        <h4 id="8">
          8. <strong>WHAT ARE YOUR PRIVACY RIGHTS?</strong>
        </h4>
        <p>
          <strong>In Short:</strong> In some regions, such as the European
          Economic Area (EEA), United Kingdom (UK), and Switzerland, you have
          rights that allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time.
        </p>
        <p>
          In some regions (like the EEA, UK, and Switzerland), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision-making. In certain circumstances, you may also have
          the right to object to the processing of your personal information.
          You can make such a request by contacting us by using the contact
          details provided in the section{" "}
          <a href="#11">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</a> below.
        </p>
        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your{" "}
          <strong>Member State data protection authority</strong> or{" "}
          <strong>UK data protection authority</strong>.
        </p>
        <p>
          If you are located in Switzerland, you may contact the{" "}
          <strong>Federal Data Protection and Information Commissioner</strong>.
        </p>
        <p>
          <strong>Withdrawing your consent:</strong> If we are relying on your
          consent to process your personal information, you have the right to
          withdraw your consent at any time. You can withdraw your consent at
          any time by contacting us by using the contact details provided in the
          section <a href="#11">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</a>{" "}
          below or updating your preferences.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </p>

        <h3>Account Information</h3>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will immediately and
          permanently delete your account and all associated information from
          our systems. This action is irreversible, and all data will be removed
          without the possibility of recovery. We do not retain any data after
          account termination, ensuring your information is completely erased
          from our records.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at <strong>hellomoodsense@gmail.com</strong>.
        </p>

        <h4 id="9">
          9. <strong>CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
        </h4>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>

        <h4 id="10">
          10. <strong>DO WE MAKE UPDATES TO THIS NOTICE?</strong>
        </h4>
        <p>
          <strong>In Short:</strong> Yes, we will update this notice as
          necessary to stay compliant with relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated <strong>"Revised"</strong>{" "}
          date and the updated version will be effective as soon as it is
          accessible. If we make material changes to this privacy notice, we may
          notify you either by prominently posting a notice of such changes or
          by directly sending you a notification. We encourage you to review
          this privacy notice frequently to be informed of how we are protecting
          your information.
        </p>

        <h4 id="11">
          11. <strong>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
        </h4>
        <p>
          If you have questions or comments about this notice, you may email us
          at <strong>hellomoodsense@gmail.com</strong> or contact us by post at:
        </p>
        <p>
          <strong>David Klausz</strong>
          <br />
          40-42 Grand Rue
          <br />
          Wasserbillig 6630
          <br />
          Luxembourg
        </p>

        <h4 id="12">
          12.{" "}
          <strong>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </strong>
        </h4>
        <p>
          You have the right to review, update, or delete the personal
          information we collect from you, in accordance with the applicable
          laws of your country. Within the app, you can easily export all the
          data we have stored about you. Additionally, if you wish to
          permanently delete your account and all associated data, you can do so
          directly within the app. This action will be immediate and
          irreversible.
        </p>
        <p>
          Alternatively, you can also remove your account by visiting our
          website at{" "}
          <a href="https://account.moodsenseapp.com" target="blank">
            https://account.moodsenseapp.com
          </a>
          , where you can request the permanent deletion of your account.
        </p>
      </div>
    </>
  );
};
